<template>
    <div class="lesson-avialable">
        <div class="title">
            <h4>
                {{ $t("teacher.profile.available-lesson") }}
            </h4>
        </div>
        <div v-if="lesson.data.length > 0" >
            <template v-for="(item, key) in lesson.data">
                <router-link class="no-decoration" :to="{name:'lesson.detail', params:{lessonId:item.id}}" :key="key">
                    <div class="row item">
                        <div class="col-4">
                            <img :src="item.thumbnail"/>
                        </div>
                        <div class="col-8 pl-5">
                            <div class="d-flex justify-content-between">
                                <div class="level-info" v-if="item.level">
                                    <label>{{ $t(`lesson.${item.level}`) }}</label>
                                </div>
                                <div class="price">
                                    <label>{{ formatCurrency(item.currency_code, item.price) }}</label>
                                </div>
                            </div>
                            <div class="item-title">{{ item.title }}</div>
                        </div>
                    </div>
                </router-link>
            </template>
        </div>
        <div class="border p-3 text-break text-wrap text-center" v-else>
            {{ $t('teacher.profile.no-item-sale') }}
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-more" @click="handleLoadMore" :disabled="_noMoreLesson" v-if="!_noMoreLesson">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>  {{ $t("teacher.profile.button.see-more") }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            lesson: {
                data:[],
                meta: {}
            },
            filter: {
                page: 1,
            },
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            getAvailableLesson: "mypage/getMypageAvailableLesson",
        }),
        _noMoreLesson() {
            let { meta } = this.lesson;
            return meta?.pagination?.last > this.filter.page ? false : true;
        },
    },
    async created() {
        await this.fetchAvailableLesson({
            id: this.$route.params.teacherId,
            limit: 3,
            page: this.filter.page,
        });
        this.lesson = this.getAvailableLesson;
    },
    methods: {
        ...mapActions({
            fetchAvailableLesson: "mypage/fetchMypageAvailableLesson",
        }),
        async handleLoadMore() {
            if (this.loading) return;
            this.loading = true;
            this.filter.page = this.filter.page + 1;
            await this.fetchAvailableLesson({
                id: this.$route.params.teacherId,
                limit: 3,
                page: this.filter.page,
            });
            let items = this.lesson.data;
            this.lesson = {
                meta: this.getAvailableLesson.meta,
                data: items.concat(this.getAvailableLesson.data)
            };
            this.loading = false;
        },
        _formatTime(start, end) {
            return (
                this.dateInstance(start).format("HH:mm") +
                " - " +
                this.dateInstance(end).format("HH:mm")
            );
        },
    },
};
</script>

<style scoped >
.lesson-avialable .item {
    padding-bottom: 30px;
    margin-top: 20px;
    border-bottom: 1px solid #DEDEDE;
}

.lesson-avialable .item img {
    width: 100%;
    height: 100%;
    max-width: 175px;
    max-height: 116px;
    min-width: 150px;
    min-height: 100px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 8px;
}


.lesson-avialable .item .item-title {
    text-align: left;
    letter-spacing: 0.18px;
    color: #222222;
    font-weight: 700;
    margin-bottom: 16px;
}

.lesson-avialable .item .level-info label {
    background: #AACF70 0% 0% no-repeat padding-box;
    border-radius: 25px;
    padding: 4px 0px;
    min-width: 92px;
    height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 700;
}

.lesson-avialable .item .item-title {
    text-align: left;
    color: #222222;
    font-weight: 700;
    margin-bottom: 16px;
}

.lesson-avialable .item .price label {
    text-align: left;
    color: #222222;
    font-weight: 700;
}

.lesson-avialable .title {
    border-bottom: 3px solid #F39800;
    margin-bottom: 10px;
}
</style>